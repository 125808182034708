import type { FunctionComponent } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import {
  Button,
  Typography,
  RadioGroupCard,
  RequiredValidator,
} from "@lysaab/ui-2";
import { dataLifePensionMove } from "../../../../../../data/dataLifePensionMove";

import "./DeliveryMethodInput.scss";

interface DownloadApplicationLinkProps {
  caseId: string;
  moveId: string | undefined;
}

const DownloadApplicationLink: FunctionComponent<
  DownloadApplicationLinkProps
> = ({ caseId, moveId }) => {
  return (
    <Button
      className="DeliveryMethodInput__download-link"
      component="a"
      type="button"
      download
      variant="secondary"
      icon="Deposit"
      target="_blank"
      block
      label={
        <FormattedMessage id="sweden.mailing-instructions.download.button" />
      }
      href={dataLifePensionMove.getPensionMoveApplicationUrl(
        moveId ?? caseId,
        moveId ? "move" : "case"
      )}
    />
  );
};

export type DeliveryMethod = "DOWNLOAD" | "MAIL" | undefined;

interface DeliveryMethodChoiceInputProps {
  caseId: string;
  moveId: string | undefined;
  value: DeliveryMethod;
  onChange: (deliveryMethod: DeliveryMethod) => void;
}

const DeliveryMethodChoiceInput: FunctionComponent<
  DeliveryMethodChoiceInputProps
> = ({ caseId, moveId, value, onChange }) => {
  const intl = useIntl();
  return (
    <RadioGroupCard
      alternatives={[
        {
          header: intl.formatMessage({
            id: "sweden.mailing-instructions.download.header",
            defaultMessage: "Ladda ner flyttansökan",
          }),
          description: intl.formatMessage({
            id: "sweden.mailing-instructions.download.description",
            defaultMessage: "Ladda ner och skriv ut ansökan själv",
          }),
          expandableContent: (
            <DownloadApplicationLink caseId={caseId} moveId={moveId} />
          ),
          value: "DOWNLOAD",
        },
        {
          header: intl.formatMessage({
            id: "sweden.mailing-instructions.mail.header",
            defaultMessage: "Skicka hem flyttansökan",
          }),
          description: intl.formatMessage({
            id: "sweden.mailing-instructions.mail.description",
            defaultMessage: "Skickas till din folkbokföringsadress",
          }),
          expandableContent: <></>,
          value: "MAIL",
        },
      ]}
      legend=""
      onChange={onChange}
      value={value}
      validators={[
        new RequiredValidator(
          intl.formatMessage({
            id: "sweden.mailing-instructions.error",
            defaultMessage: "Välj ett alternativ",
          })
        ),
      ]}
    />
  );
};

interface Props {
  caseId: string;
  moveId?: string;
  value: DeliveryMethod;
  onChange: (deliveryMethod: DeliveryMethod) => void;
}

export const DeliveryMethodInput: FunctionComponent<Props> = ({
  caseId,
  moveId,
  value,
  onChange,
}) => {
  return (
    <div className="DeliveryMethodInput">
      <div>
        <Typography type="h3">
          <FormattedMessage
            id="sweden.mailing-instructions.header"
            defaultMessage="Ladda ner eller skicka hem?"
          />
        </Typography>
        <Typography>
          <FormattedMessage
            id="sweden.mailing-instructions.description"
            defaultMessage="Du behöver inget frimärke, Lysa står för portot."
          />
        </Typography>
        <DeliveryMethodChoiceInput
          caseId={caseId}
          moveId={moveId}
          value={value}
          onChange={onChange}
        />
      </div>
    </div>
  );
};
