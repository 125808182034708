import { FunctionComponent, useEffect, useMemo, useRef, useState } from "react";

import {
  Button,
  CardList,
  CardListItem,
  CircleButton,
  Form,
  InputErrorList,
  LysaFormRef,
  NewIcon,
  RequiredValidator,
  SNACKBAR_TYPES,
  Snackbar,
  Spinner,
  Tickbox,
  Typography,
  useForm,
  useValidation,
} from "@lysaab/ui-2";
import { useIntl } from "react-intl";
import { AnimatePresence, motion } from "framer-motion";
import {
  InsuranceCompaniesStatusResponse,
  dataLifePensionMove,
  Institute,
  CollectionStatusResponse,
  InsuranceCompany,
  completedStatuses,
} from "../../../../../data/dataLifePensionMove";
import { useTransfer } from "../TransferContext";
import { Modal } from "../../../../../components/modal/Modal";
import { InsurelyDoc } from "../components/insurelyDoc/InsurelyDoc";
import { institutePrettyNames } from "../utils/institutePrettyNames";
import { TranslatedText } from "../../../../../components/TranslatedText";
import { PensionLogo } from "../../../../../components/pensionLogo/PensionLogo";
import { ManualModalContent } from "../components/manualModalContent/ManualModalContent";

import "./CollectionMethods.scss";

interface Props {
  toInsuranceSigningList: () => void;
  toGroupIntoInsurance: () => void;
}

export type InsuranceCompaniesStatus = InsuranceCompaniesStatusResponse;

export const CollectionMethods: FunctionComponent<Props> = ({
  toInsuranceSigningList,
  toGroupIntoInsurance,
}) => {
  const formRef = useRef<LysaFormRef>();
  /**
   * insuranceCompanies is the lists of companies from which we
   * use Insurely to automatically retreive possible moves
   */
  const [insuranceCompanies, setInsuranceCompanies] =
    useState<InsuranceCompaniesStatus[]>();
  /**
   * checkedInsuranceCompanies is used to keep track of checkedInsuranceCompanies
   * and the require validator
   */
  const [checkedInsuranceCompanies, setCheckedInsuranceCompanies] = useState<{
    [insuranceCompany: string]: boolean;
  }>({});

  const [previousResult, setPreviousResult] = useState<InsuranceCompany[]>([]);
  const [hasPreviousCompletedResult, setHasPreviousCompletedResult] =
    useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [transfer, setTransfer] = useTransfer();

  useEffect(() => {
    dataLifePensionMove.getInsuranceCompaniesStatus().then((response) => {
      const data = response.map((company) => {
        return { ...company };
      });
      setInsuranceCompanies(data);
    });
  }, []);

  /**
   * Synchronize checked status with backend to restore previous selection.
   */
  useEffect(() => {
    dataLifePensionMove.getInsuranceCompanies().then((insuranceCompanies) => {
      const pollPromises = insuranceCompanies.map((insuranceCompany) => {
        return dataLifePensionMove.collectionStatus(
          insuranceCompany.insuranceCompany
        );
      });

      Promise.allSettled(pollPromises).then((insuranceData) => {
        const previousResult = insuranceData
          .filter(
            (
              response
            ): response is PromiseFulfilledResult<CollectionStatusResponse> => {
              return response.status === "fulfilled";
            }
          )
          .map((res) => res.value.insuranceCompany);

        const completedInsuranceData = insuranceData
          .filter(
            (
              response
            ): response is PromiseFulfilledResult<CollectionStatusResponse> => {
              return response.status === "fulfilled";
            }
          )
          .some((res) => completedStatuses.includes(res.value.status));

        if (completedInsuranceData) {
          setHasPreviousCompletedResult(true);
        }

        /**
         * Repopulate checkboxes in case user return from InsuranceSigningList
         */
        if (transfer.hasVisitedInsuranceSigning) {
          const checked = insuranceCompanies.reduce((acc, item) => {
            acc[item.insuranceCompany] = true;
            return acc;
          }, {} as Record<string, boolean>);

          setCheckedInsuranceCompanies(checked);
        }

        setPreviousResult(previousResult);
      });
    });
  }, [transfer.hasVisitedInsuranceSigning]);

  const allCheckedHasResult = Object.keys(checkedInsuranceCompanies).every(
    (key) => previousResult.includes(key as InsuranceCompany)
  );
  const allPreviousResultsAreChecked = previousResult.every(
    (value) =>
      value in checkedInsuranceCompanies &&
      checkedInsuranceCompanies[value] === true
  );

  const hasChanged = !(allCheckedHasResult && allPreviousResultsAreChecked);

  if (!insuranceCompanies) {
    return <Spinner />;
  }

  return (
    <section className="transfer-pension-collection-methods">
      <Form
        lysaFormRef={formRef}
        onSubmit={(evt) => {
          // Submit events from ManualModalContent sometimes bubbles in this
          // form before inside ManualModalContent, making the stopPropagation in
          // ManualModalContent useless. But only sometimes.
          // We have not found the cause of this, but this check remedies this,
          // when it is a "real" submit event from this form target and currentTarget is the same.
          // TODO: The real solution for this is to move the ManualModalContent outside of this form.
          // Event htough it is rendered outside this DOM, react portals propagates all events as if they
          // happened in the React hierarchy. Then we get nested forms which seems troublesome.
          if (evt.target !== evt.currentTarget) {
            return;
          }
          const selectedCompaniesToScrape = insuranceCompanies
            .filter(
              (company) => checkedInsuranceCompanies[company.insuranceCompany]
            )
            .map((company) => company.insuranceCompany);

          const selectedCompaniesManuallyAdded = transfer.moves.filter(
            (company) => company.checked
          );

          if (
            formRef.current?.isValid &&
            selectedCompaniesToScrape.length > 0
          ) {
            if (!hasChanged) {
              toInsuranceSigningList();
            } else {
              dataLifePensionMove
                .initiateCollection(selectedCompaniesToScrape)
                .then((_) => {
                  setTransfer({
                    moves: selectedCompaniesManuallyAdded,
                    collection: selectedCompaniesToScrape.join(","),
                  });
                  toInsuranceSigningList();
                });
            }
          } else if (formRef.current?.isValid && transfer.moves.length > 0) {
            setTransfer({ moves: selectedCompaniesManuallyAdded });
            toGroupIntoInsurance();
          }
        }}
      >
        <FormContent
          insuranceCompanies={insuranceCompanies}
          checkedInsuranceCompanies={checkedInsuranceCompanies}
          setCheckedInsuranceCompanies={setCheckedInsuranceCompanies}
          hasPreviousResult={hasPreviousCompletedResult}
          toInsuranceSigningList={toInsuranceSigningList}
        />
      </Form>
    </section>
  );
};

function FormContent({
  insuranceCompanies,
  checkedInsuranceCompanies,
  setCheckedInsuranceCompanies,
  hasPreviousResult,
  toInsuranceSigningList,
}: {
  insuranceCompanies: InsuranceCompaniesStatus[];
  checkedInsuranceCompanies: { [insuranceCompany: string]: boolean };
  setCheckedInsuranceCompanies: React.Dispatch<
    React.SetStateAction<{ [insuranceCompany: string]: boolean }>
  >;
  hasPreviousResult?: boolean;
  toInsuranceSigningList: () => void;
}) {
  const intl = useIntl();
  const [inputName] = useState(
    "insurance_collection_methods_group_" +
      Math.random().toString(36).substr(2, 9)
  );
  const containerRef = useRef<HTMLFieldSetElement>(null);
  const [selectAll, setSelectAll] = useState(false);
  const [addManual, setAddManual] = useState(false);
  const [editManual, setEditManual] = useState(false);
  const [transfer, setTransfer] = useTransfer();

  /**
   * Values are used to validate the form, making sure the user can't submit the form before everything is ok.
   * In our case we only have a require validator so essentially making sure at least one move is selected.
   */
  const values = useMemo(() => {
    const automatic = Object.values(checkedInsuranceCompanies).filter(Boolean);
    const manual: Array<boolean> = transfer.moves.some((cur) => cur.checked)
      ? [true]
      : [];
    return [...automatic, ...manual];
  }, [checkedInsuranceCompanies, transfer.moves]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [validity, errorMessages, validityClasses, resetValidation] =
    useValidation<boolean[]>(
      values,
      [
        new RequiredValidator(
          intl.formatMessage({
            id: "sweden.transfer-pension.collection-methods.noCompaniesSelected.warning",
          })
        ),
      ],
      false
    );

  useForm(
    inputName,
    validity,
    values,
    resetValidation,
    containerRef,
    errorMessages
  );

  const allAreDown = insuranceCompanies.every(
    ({ downTimeReason }) => downTimeReason
  );

  /**
   * First time at this stage in the story the moves in transfer is only manually added.
   * However, since user can go back in the flow the subsequent times moves can also include move's added from scraping.
   * Thus, we need a way to distinguise the manual moves from the scraped moves. We do it with a flag. Like it or make it better.
   * */
  const manualMoves = transfer.moves.filter((move) => move.isManuallyAdded);

  const collective = [Institute.AMF, Institute.ALECTA, Institute.KPA];

  if (allAreDown) {
    return (
      <>
        <Typography type="h1">
          <TranslatedText id="sweden.transfer-pension.collection-methods.header" />
        </Typography>
        <Typography type="body">
          <TranslatedText id="sweden.transfer-pension.collection-methods.ingress" />
        </Typography>
        <Snackbar type={SNACKBAR_TYPES.ERROR} icon>
          <TranslatedText id="sweden.transfer-pension.collection-methods.all-down" />
        </Snackbar>
      </>
    );
  }

  return (
    <>
      <Typography type="h1">
        <TranslatedText id="sweden.transfer-pension.collection-methods.header" />
      </Typography>
      <Typography type="body">
        <TranslatedText id="sweden.transfer-pension.collection-methods.ingress" />
      </Typography>
      {hasPreviousResult && (
        <section className="previous-result">
          <Typography type="body">
            <TranslatedText id="sweden.transfer-pension.collection-methods.previous-result" />
          </Typography>
          <Button
            block
            type="button"
            variant="outlined"
            label="Fortsätt där du slutade senast"
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
              toInsuranceSigningList();
            }}
          />
        </section>
      )}
      <fieldset ref={containerRef} className="insurance-company-list-fieldset">
        <CardList shadowStyle={false} className="card-list">
          <CardListItem className="insurance-company-list-item">
            <span className="insurance-company">
              <span className="icon-circle">
                <NewIcon.Search />
              </span>
              <Typography type="label-large">
                <TranslatedText id="sweden.transfer-pension.collection-methods.select-all" />
              </Typography>
            </span>
            <span className="tickbox">
              <Tickbox
                size={18}
                checked={selectAll}
                alternative={{
                  text: "",
                  value: "selectAll",
                }}
                onChange={(_newValue) => {
                  let nextChecked;
                  if (!selectAll) {
                    nextChecked = insuranceCompanies.reduce((acc, current) => {
                      if (current?.downTimeReason) {
                        return { ...acc };
                      } else {
                        return { ...acc, [current.insuranceCompany]: true };
                      }
                    }, {});
                  } else {
                    nextChecked = insuranceCompanies.reduce((acc, current) => {
                      if (current?.downTimeReason) {
                        return { ...acc };
                      } else {
                        return { ...acc, [current.insuranceCompany]: false };
                      }
                    }, {});
                  }
                  setCheckedInsuranceCompanies(nextChecked);
                  setSelectAll((previous) => !previous);
                }}
              />
            </span>
          </CardListItem>
          <AnimatePresence>
            {selectAll && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{
                  opacity: 0,
                  height: 0,
                }}
                transition={{ duration: 0.3 }}
              >
                <CardListItem className="select-all-info">
                  <NewIcon.Alert
                    size={24}
                    primaryColor="var(--lysa-brand-blue)"
                    className="icon"
                  />
                  <Typography type="body" className="body">
                    <TranslatedText id="sweden.transfer-pension.collection-methods.select-all.info" />
                  </Typography>
                </CardListItem>
              </motion.div>
            )}
          </AnimatePresence>
          {insuranceCompanies.map(
            ({
              insuranceCompany,
              insuranceCompanyDisplayName,
              downTimeReason,
            }) => {
              const isChecked = checkedInsuranceCompanies[insuranceCompany];
              return (
                <CardListItem
                  key={insuranceCompany}
                  className="insurance-company-list-item"
                >
                  <span className="insurance-company">
                    <PensionLogo
                      pension={{ key: insuranceCompany }}
                      size={48}
                      className="insurance-company-logo"
                    />
                    <span>
                      <span>
                        <Typography type="label-large">
                          {insuranceCompanyDisplayName}
                        </Typography>
                        {collective.includes(insuranceCompany) && (
                          <Typography
                            type="body"
                            className="insurance-company-list-item-collective"
                          >
                            <TranslatedText id="sweden.transfer-pension.collection-methods.almost-only-collective" />
                          </Typography>
                        )}
                      </span>
                      {downTimeReason && (
                        <Typography
                          type="body-small"
                          className="insurance-company-error-text"
                        >
                          <TranslatedText id="sweden.transfer-pension.collection-methods.down-time" />
                        </Typography>
                      )}
                    </span>
                  </span>
                  {!downTimeReason && (
                    <span className="tickbox">
                      <Tickbox
                        size={18}
                        checked={isChecked}
                        alternative={{
                          text: "",
                          value: insuranceCompany,
                        }}
                        onChange={(_newValue) => {
                          if (selectAll) {
                            setSelectAll(false);
                          }
                          setCheckedInsuranceCompanies((oldChecked) => {
                            return {
                              ...oldChecked,
                              [insuranceCompany]: !oldChecked[insuranceCompany],
                            };
                          });
                        }}
                        validators={[]}
                      />
                    </span>
                  )}
                </CardListItem>
              );
            }
          )}
        </CardList>
      </fieldset>
      {manualMoves.length > 0 && (
        <section className="manual-moves">
          <Typography type="h4">
            <TranslatedText id="sweden.transfer-pension.collection-methods.manual.header" />
          </Typography>
          <CardList shadowStyle={false} className="card-list">
            {manualMoves.map((move) => {
              const { institute, checked = true, id, insuranceNumber } = move;
              return (
                <CardListItem key={id} className="insurance-company-list-item">
                  <span className="insurance-company">
                    <PensionLogo
                      pension={{ key: institute }}
                      size={48}
                      className="insurance-company-logo"
                    />
                    <label htmlFor={institute}>
                      <Typography type="label-large">
                        {institute
                          ? institutePrettyNames[institute]
                          : institute}
                      </Typography>
                      <Typography
                        type="body"
                        className="manual-move-insurance-number"
                      >
                        {insuranceNumber}
                      </Typography>
                    </label>
                  </span>
                  <span className="manual-move-actions">
                    <CircleButton
                      type="button"
                      icon="Edit"
                      variant="outlined"
                      onClick={() => {
                        setEditManual(true);
                      }}
                    />
                    <Modal
                      header={intl.formatMessage({
                        id: "sweden.transfer-pension.collection-methods.edit-information-manually.modal.header",
                      })}
                      showModal={editManual}
                      onClose={() => {
                        setEditManual(false);
                      }}
                      closeOnOverlayClick
                    >
                      <ManualModalContent
                        closeModal={() => {
                          setEditManual(false);
                        }}
                        incoming={move}
                        type="edit"
                      />
                    </Modal>
                    <CircleButton
                      type="button"
                      icon="Delete"
                      variant="outlined"
                      onClick={() => {
                        const currentMoves = manualMoves;
                        const nextMoves = currentMoves.filter(
                          (move) => move.id !== id
                        );
                        setTransfer({ moves: nextMoves });
                      }}
                    />
                    <Tickbox
                      size={18}
                      checked={checked}
                      alternative={{
                        text: "",
                        value: institute,
                      }}
                      onChange={(newValue) => {
                        const currentMoves = manualMoves;
                        const nextMoves = currentMoves.map((currentMove) => {
                          if (currentMove.id === id) {
                            return {
                              ...currentMove,
                              checked: !currentMove.checked,
                            };
                          } else {
                            return currentMove;
                          }
                        });
                        setTransfer({ moves: nextMoves });
                      }}
                      validators={[]}
                    />
                  </span>
                </CardListItem>
              );
            })}
          </CardList>
        </section>
      )}
      <Button
        block
        variant="secondary"
        type="button"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.collection-methods.add-information-manually.button",
        })}
        onClick={() => {
          setAddManual(true);
        }}
      />
      <Modal
        header={intl.formatMessage({
          id: "sweden.transfer-pension.collection-methods.add-information-manually.modal.header",
        })}
        showModal={addManual}
        onClose={() => {
          setAddManual(false);
        }}
        closeOnOverlayClick
      >
        <ManualModalContent
          closeModal={() => {
            setAddManual(false);
          }}
          type="add"
        />
      </Modal>
      <Typography type="body" className="accept-terms">
        <TranslatedText id="sweden.transfer-pension.collection-methods.add-manually.accept-terms" />
      </Typography>
      <InsurelyDoc />
      {errorMessages.length > 0 && (
        <div className="error-container">
          <InputErrorList errorMessages={errorMessages} />
        </div>
      )}
      <Button
        className="primary-button"
        block
        variant="primary"
        type="submit"
        label={intl.formatMessage({
          id: "sweden.transfer-pension.collection-methods.submit.button",
        })}
      />
    </>
  );
}
