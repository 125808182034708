import type { FunctionComponent } from "react";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { SNACKBAR_TYPES, Snackbar, Typography, Button } from "@lysaab/ui-2";
import {
  ActivePensionMoveInResponse,
  waitingForInsuredStatuses,
  dataLifePensionMove,
  PensionMoveSigningOptions,
} from "../../../data/dataLifePensionMove";
import { CardButton } from "./CardButton";
import { Modal } from "../../../components/modal/Modal";
import { MoveCard } from "../../../countries/sweden/pages/transferPensions/components/moveCard/MoveCard";
import { MoveSteps } from "../../../countries/sweden/pages/transferPensions/components/moveSteps/MoveSteps";
import { AccountType } from "../../../data/dataAccounts";

import "./PendingPensions.scss";
import { DigitalSigningInstructions } from "../../../countries/sweden/pages/transferPensions/components/digitalSigningInstructions/DigitalSigningInstructions";
import { useHistory, useLocation } from "react-router";

interface MoveDownloadLinkProps {
  move: ActivePensionMoveInResponse;
}

// TODO: An almost exact copy of this lives in DeliveryMethodInput.tsx.
// We should extract this to a shared component.
const MoveDownloadLink: FunctionComponent<MoveDownloadLinkProps> = ({
  move,
}) => {
  return (
    <Button
      className="MoveDownloadLink"
      component="a"
      type="button"
      download
      variant="secondary"
      icon="Deposit"
      target="_blank"
      block
      label={
        <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.download.button" />
      }
      href={dataLifePensionMove.getPensionMoveApplicationUrl(move.id, "move")}
    />
  );
};

interface SendHomeButtonProps {
  move: ActivePensionMoveInResponse;
  onSentHome: () => void;
}

const SendHomeButton: FunctionComponent<SendHomeButtonProps> = ({
  move,
  onSentHome,
}) => {
  const intl = useIntl();
  const [error, setError] = useState<string | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const setSendHome = useCallback(() => {
    setError(undefined);
    setIsLoading(true);
    dataLifePensionMove
      .setDeliveryMethodForMove(move.id, "MAIL")
      .then(() => {
        setIsLoading(false);
        onSentHome();
      })
      .catch(() => {
        setError(
          intl.formatMessage({
            id: "sweden.pending-pensions.modal.manual-signing.send-home.error",
            defaultMessage:
              "Något gick fel när valet skulle sparas, försök igen senare",
          })
        );
        setIsLoading(false);
      });
  }, [intl, move.id, onSentHome]);

  return (
    <>
      <Button
        className="SendHomeButton"
        variant="secondary"
        icon="Message"
        disabled={isLoading}
        block
        label={
          <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.send-home.button" />
        }
        onClick={setSendHome}
      />
      {error && <Snackbar type={SNACKBAR_TYPES.ERROR}>{error}</Snackbar>}
    </>
  );
};

interface ManualSigningProps {
  move: ActivePensionMoveInResponse;
  onSentHome: () => void;
}

const ManualSigning: FunctionComponent<ManualSigningProps> = ({
  move,
  onSentHome,
}) => {
  return (
    <div className="ManualSigning">
      <Typography type="label" component="h3">
        <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.header" />
      </Typography>

      {move.state === "WAITING_SEND_INSURED" ? (
        <>
          <Typography type="body">
            <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.sending" />
          </Typography>
          <MoveDownloadLink move={move} />
        </>
      ) : (
        <>
          <Typography type="body">
            <FormattedMessage id="sweden.pending-pensions.modal.manual-signing.text" />
          </Typography>
          <SendHomeButton move={move} onSentHome={onSentHome} />
          <MoveDownloadLink move={move} />
        </>
      )}
    </div>
  );
};

function searchParamsWithMoveId(moveId: string) {
  const newSearch = new URLSearchParams(window.location.search);
  newSearch.set("moveId", moveId);
  return `?${newSearch.toString()}`;
}

function searchParamsWithoutMoveId() {
  const newSearch = new URLSearchParams(window.location.search);
  newSearch.delete("moveId");
  // Reset signedMoveId if the modal was opened from a scrive redirect.
  newSearch.delete("signedMoveId");
  return `?${newSearch.toString()}`;
}

function useUrlQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

function useActiveMoveId() {
  const urlQuery = useUrlQuery();
  return urlQuery.get("signedMoveId") || urlQuery.get("moveId");
}

function useJustSignedMoveId() {
  const urlQuery = useUrlQuery();
  return urlQuery.get("signedMoveId");
}

function useModalState() {
  const history = useHistory();
  const openModal = useCallback(
    (move: ActivePensionMoveInResponse) => {
      history.replace({
        search: searchParamsWithMoveId(move.id),
      });
    },
    [history]
  );
  const closeModal = useCallback(() => {
    history.replace({
      search: searchParamsWithoutMoveId(),
    });
  }, [history]);

  const activeMoveId = useActiveMoveId();
  const [modalOpened, setModalOpened] = useState(Boolean(activeMoveId));

  useEffect(() => {
    if (activeMoveId) {
      setModalOpened(true);
    }
  }, [activeMoveId]);

  // Delay setting query params until modal has animated out.
  const setActiveMoveDelayed = useCallback(() => {
    setModalOpened(false);
    setTimeout(() => {
      closeModal();
    }, 250);
  }, [closeModal]);

  return { modalOpened, openModal, closeModal: setActiveMoveDelayed };
}

export const PendingPensions: FunctionComponent = () => {
  const intl = useIntl();
  const [moves, setMoves] = useState<ActivePensionMoveInResponse[]>([]);
  const activeMoveId = useActiveMoveId();
  const justSignedMoveId = useJustSignedMoveId();
  const { modalOpened, openModal, closeModal } = useModalState();

  const fetchMoves = useCallback(() => {
    dataLifePensionMove.getActiveMoves().then((response) => {
      setMoves(response);
    });
  }, []);

  useEffect(() => {
    fetchMoves();
  }, [fetchMoves, activeMoveId]);

  let activeMove;
  if (moves.length) {
    activeMove = moves.find((move) => move.id === activeMoveId);
  }

  if (moves.length === 0) {
    return null;
  }

  return (
    <div className="overview-pending">
      <Typography type="h2">
        <FormattedMessage id="sweden.pending-pensions.header" />
      </Typography>

      <div className="list">
        {moves.map((move) => {
          const text =
            move.type === AccountType.LYSA_PPF
              ? intl.formatMessage({
                  id: "sweden.pending-pensions.private-insurance",
                })
              : intl.formatMessage(
                  {
                    id: "sweden.pending-pensions.pension-from",
                  },
                  {
                    employer:
                      move.employer ??
                      intl.formatMessage({
                        id: "sweden.pending-pensions.pension-from.unknown",
                      }),
                  }
                );

          return (
            <CardButton
              key={move.id}
              text={text}
              onClick={() => {
                openModal(move);
              }}
            />
          );
        })}
      </div>

      <Modal
        header={intl.formatMessage({
          id: "sweden.pending-pensions.modal.header",
        })}
        showModal={modalOpened}
        onClose={() => {
          closeModal();
        }}
        closeOnOverlayClick
      >
        {activeMove && activeMove.id === activeMoveId && (
          <div>
            <div className="PendingPensions__moves">
              <MoveCard
                insuranceCompany={activeMove.institute}
                insuranceNumber={activeMove.insuranceNumber}
                insuranceHolderName={activeMove.employer}
                moveAccountType={activeMove.type}
              />
            </div>

            {waitingForInsuredStatuses.includes(activeMove.state) &&
              // TODO: IMPLEMENT REAL
              activeMove.signing === PensionMoveSigningOptions.MANUAL && (
                <ManualSigning move={activeMove} onSentHome={fetchMoves} />
              )}

            {waitingForInsuredStatuses.includes(activeMove.state) &&
              activeMove.signing === PensionMoveSigningOptions.SCRIVE &&
              activeMove.id !== justSignedMoveId && (
                <DigitalSigningInstructions move={activeMove} />
              )}

            <div className="PendingPensions__steps-header">
              <Typography type="label">
                <FormattedMessage id="sweden.pending-pensions.modal.steps.header" />
              </Typography>
            </div>

            <Snackbar type={SNACKBAR_TYPES.INFO} icon textAlign="left">
              <FormattedMessage id="sweden.pending-pensions.modal.steps.notification" />
            </Snackbar>

            <MoveSteps
              pensionMoveStatuses={[activeMove.state]}
              accountTypes={[activeMove.type]}
              justSignedMove={activeMove.id === justSignedMoveId}
            />
          </div>
        )}
      </Modal>
    </div>
  );
};
