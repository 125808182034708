import { FunctionComponent, useCallback } from "react";
import { TranslatedText } from "../../../../../components/TranslatedText";
import type { Move } from "../TransferContext";
import type { UiScriveStatus } from "./useScrive";
import { Loading } from "./Loading";
import { Button, NewIcon, Typography } from "@lysaab/ui-2";

import "./SignMove.scss";
import { PensionLogo } from "../../../../../components/pensionLogo/PensionLogo";
import { institutePrettyNames } from "../utils/institutePrettyNames";
import { Amount } from "../../../../../components/amount/Amount";
import { AccountType } from "../../../../../data/dataAccounts";

type StartSigningFn = (options: {
  moveId: string;
  callbackUrl: string;
}) => void;

const RetryStatus = ({
  move,
  onStartSigning,
}: {
  move: Move;
  onStartSigning: () => void;
}) => (
  <div className="retry-status">
    <span className="header">
      <NewIcon.WarningOutline />
      <Typography>
        <TranslatedText
          id="sweden.transfer-pension.sign-move-deluxe.retry-status.message"
          defaultMessage="Signering avbröts"
        />
      </Typography>
    </span>
    <Button
      block
      onClick={() => move.id && onStartSigning()}
      variant="secondary"
      icon="Retry"
      label={
        <TranslatedText
          id="sweden.transfer-pension.sign-move-deluxe.retry-status.button"
          defaultMessage="Försök igen"
        />
      }
    />
  </div>
);

const IdleStatus = ({
  move,
  onStartSigning,
}: {
  move: Move;
  onStartSigning: () => void;
}) => (
  <Button
    block
    onClick={() => move.id && onStartSigning()}
    label={
      <TranslatedText
        id="sweden.transfer-pension.sign-move-deluxe.idle-status.button"
        defaultMessage="Signera flytt"
      />
    }
  />
);

const WaitingStatus = () => (
  <div className="waiting-status">
    <Loading />
    <Typography type="body">
      <TranslatedText
        id="sweden.transfer-pension.sign-move-deluxe.waiting-status.button"
        defaultMessage="Hämtar information"
      />
    </Typography>
  </div>
);

interface SignActionProps {
  status: UiScriveStatus;
  isNextToSign: boolean;
  move: Move;
  startSigning: StartSigningFn;
}

export const SignAction: FunctionComponent<SignActionProps> = ({
  status,
  isNextToSign,
  move,
  startSigning,
}) => {
  const onStartSigning = useCallback(() => {
    if (move.id) {
      startSigning({ moveId: move.id, callbackUrl: window.location.href });
    }
  }, [move.id, startSigning]);
  const { uiStatus } = status;

  if (uiStatus !== "RETRY" && !isNextToSign) return null;

  let action = null;
  if (uiStatus === "RETRY")
    action = <RetryStatus move={move} onStartSigning={onStartSigning} />;

  if (uiStatus === "IDLE")
    action = <IdleStatus move={move} onStartSigning={onStartSigning} />;
  if (uiStatus === "WAITING" || uiStatus === "SIGNING")
    action = <WaitingStatus />;

  if (!action)
    throw new Error(`Unexpected scrive status for ${move.institute}`);

  return action;
};

const InsuranceLabel: FunctionComponent<{ move: Move }> = ({ move }) =>
  move.insuranceNumber ? (
    <dl className="sign-move-list-item">
      <dt>
        <TranslatedText
          id={"sweden.transfer-pension.move-card.insuranceNumber.label"}
        />
      </dt>
      <dd>{move.insuranceNumber}</dd>
    </dl>
  ) : null;

const AccountTypeLabel: FunctionComponent<{ move: Move }> = ({ move }) => {
  if (move.type === AccountType.LYSA_TJP) {
    return (
      <dl className="sign-move-list-item">
        <dt>
          <TranslatedText
            id={"sweden.transfer-pension.move-card.insuranceHolderName.label"}
          />
        </dt>
        <dd>{move.employer ? move.employer : move.employerTin}</dd>
      </dl>
    );
  }
  if (move.type === AccountType.LYSA_PPF) {
    return (
      <dl className="sign-move-list-item">
        <dt>
          <TranslatedText id={"sweden.transfer-pension.move-card.ppf.label"} />
        </dt>
      </dl>
    );
  }
  return null;
};

interface Props {
  move: Move;
  startSigning: StartSigningFn;
  status?: UiScriveStatus;
  isNextToSign: boolean;
}

export const SignMove: FunctionComponent<Props> = ({
  status,
  isNextToSign,
  move,
  startSigning,
}) => {
  return (
    <span className="content">
      <section className="list-item-header">
        <div className="list-item-header-left">
          <PensionLogo
            className="list-item-header-left__pension-logo"
            pension={{ key: move.institute }}
            size={42}
          />
          <div>
            <Typography type="label-large">
              {move.institute
                ? institutePrettyNames[move.institute]
                : move.institute}
            </Typography>
            <Typography type="body" component="div">
              {move.currentWorth !== undefined &&
                move.currentWorth !== null &&
                move.currentWorth !== -1 && (
                  <Amount amount={move.currentWorth} />
                )}
            </Typography>
          </div>
        </div>
        <div>{status?.uiStatus === "SIGNED" && <NewIcon.Check />}</div>
      </section>
      {status && (
        <section className="actions">
          <hr className="information-divider" />
          <div className="actions-content">
            <InsuranceLabel move={move} />
            <AccountTypeLabel move={move} />
            <SignAction
              move={move}
              status={status}
              startSigning={startSigning}
              isNextToSign={isNextToSign}
            />
          </div>
        </section>
      )}
    </span>
  );
};
