import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect, useMemo, useState } from "react";
import {
  dataLifePensionMove,
  ScriveStatusByCaseIdEntry,
} from "../../../../../data/dataLifePensionMove";

export type UiStatus = "IDLE" | "WAITING" | "SIGNING" | "SIGNED" | "RETRY";

export type UiScriveStatus = ScriveStatusByCaseIdEntry & {
  uiStatus: UiStatus;
};

type StartSigningParams = {
  moveId: string;
  callbackUrl: string;
};

type SignedMoveIdHint = string | null | undefined;

const uiScriveStatus = (
  status: ScriveStatusByCaseIdEntry,
  signedMoveIdHint: SignedMoveIdHint
): UiStatus => {
  const backendStatus = status.status;

  if (backendStatus === "DOWNLOAD" || backendStatus === "COMPLETED")
    return "SIGNED";
  if (
    backendStatus === "FAILED" ||
    backendStatus === "CANCELLED" ||
    backendStatus === "TIMEDOUT" ||
    (backendStatus === "ONGOING" &&
      status.documentOpened &&
      signedMoveIdHint !== status.accountId)
  )
    return "RETRY";
  if (!backendStatus || backendStatus === "NOT_FOUND") return "IDLE";
  if (backendStatus === "INITIATED" || backendStatus === "UPDATABLE")
    return "WAITING";
  if (backendStatus === "ONGOING") return "SIGNING";

  throw new Error(`Unexpected scrive status from backend: ${backendStatus}`);
};

const mapScriveStatus = (
  scriveStatus: ScriveStatusByCaseIdEntry,
  signedMoveIdHint: SignedMoveIdHint
) => ({
  ...scriveStatus,
  uiStatus: uiScriveStatus(scriveStatus, signedMoveIdHint),
});

export const useScriveStatusForCase = ({
  caseId,
  signedMoveIdHint,
  enabled,
  onSigningStarted,
}: {
  caseId: string;
  signedMoveIdHint?: SignedMoveIdHint;
  enabled?: boolean;
  onSigningStarted: (scriveStatus: UiScriveStatus) => void;
}) => {
  const queryClient = useQueryClient();
  const [awaitingScriveForMoveId, setAwaitingScriveForMoveId] = useState<
    string | undefined
  >();
  const startSigningMutation = useMutation({
    mutationFn: ({ moveId, callbackUrl }: StartSigningParams) => {
      const callbackUrlWithMoveId = new URL(callbackUrl);
      callbackUrlWithMoveId.searchParams.set("signedMoveId", moveId);

      return dataLifePensionMove.startScriveSigning({
        moveId,
        callbackUrl: callbackUrlWithMoveId.href,
      });
    },
    onMutate: ({ moveId }) => {
      setAwaitingScriveForMoveId(moveId);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["scriveStatusesForCase"] });
    },
    onError: () => {
      // TODO: Raise an error somehow. Maybe error boundary could be clean?
      setAwaitingScriveForMoveId(undefined);
    },
  });

  const { data: scriveStatuses } = useQuery({
    queryKey: ["scriveStatusesForCase", caseId],
    queryFn: () => dataLifePensionMove.getScriveStatusByCaseId(caseId),
    enabled: enabled && Boolean(caseId),
    refetchInterval: 1000,
    refetchIntervalInBackground: false,
    refetchOnWindowFocus: true,
  });

  useEffect(() => {
    if (awaitingScriveForMoveId && scriveStatuses) {
      const awaitingMoveStatus = scriveStatuses.find(
        (scriveStatus) => scriveStatus.accountId === awaitingScriveForMoveId
      );
      if (
        awaitingMoveStatus &&
        uiScriveStatus(awaitingMoveStatus, signedMoveIdHint) === "SIGNING"
      ) {
        setAwaitingScriveForMoveId(undefined);
        onSigningStarted(mapScriveStatus(awaitingMoveStatus, signedMoveIdHint));
      }
    }
  }, [
    awaitingScriveForMoveId,
    onSigningStarted,
    scriveStatuses,
    signedMoveIdHint,
  ]);

  const normalizedScriveStatuses = useMemo(() => {
    if (scriveStatuses) {
      return scriveStatuses.map((scriveStatus) => ({
        ...scriveStatus,
        uiStatus: uiScriveStatus(scriveStatus, signedMoveIdHint),
      }));
    }
  }, [scriveStatuses, signedMoveIdHint]);

  return { data: normalizedScriveStatuses, startSigning: startSigningMutation };
};
