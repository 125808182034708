import { FunctionComponent } from "react";
import "./Loading.scss";

export const Loading: FunctionComponent = () => {
  return (
    <div className="loading">
      <div className="loading-arc"></div>
    </div>
  );
};
